import axios from "axios";
import creds from "../creds.json";
import { RefreshToken } from "../actions/APIErrorHandler";
import { Debug } from "ss-lib";
import { pca } from '../context/UserContext';

export const resetUser = (saveUser) => {
  saveUser(undefined);
};

export const MicrosoftLogin = (
  user,
  saveUser,
  finishLogin,
  setMsg,
  setError
) => {
  //This is now Async
  if(user.current.window && !user.current.window.closed && user.current.window.focus) //Hopefully this will preven the hidden login window when you accidentally click away
    user.current.window.focus();
  else
    pca
      .loginPopup({
        scopes: ["user.read", "email"],
        prompt: "select_account",
      })
      .then((response) => {
        if(Debug()) console.log(response);
        if (response.tokenType === "id_token") {
          ProcessToken(
            response.idToken.rawIdToken,
            user,
            "Microsoft",
            saveUser,
            finishLogin,
            setMsg,
            setError
          );
        } else if (response.tokenType === "Bearer") {
          ProcessToken(
            response.idToken,
            user,
            "Microsoft",
            saveUser,
            finishLogin,
            setMsg,
            setError
          );
        } else {
          let ops = {
            scopes: ["user.read", "user.readbasic.all"],
            account: response.account,
          };
          user.current.msUserAgent.acquireTokenSilent(ops).then((next) => {
            ProcessToken(
              next.idToken,
              user,
              "Microsoft",
              saveUser,
              finishLogin,
              setMsg,
              setError
            );
          });
        }
      })
      .catch((err) => {
        if(Debug()) console.log("Error...");
        if(Debug()) console.log(err);
      });
};

export const ProcessToken = (
  token,
  refresh,
  type,
  saveUser,
  callBack,
  setMessage,
  setError,
  count
) => {
  if (setMessage) setMessage("Fetching Account Info");

  axios
    .post(
      "/api/auth",
      { signon: type },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
    .then((response) => {
      if (Debug()) {
        console.log("Processing Token");
        console.log(response);
      }
      if (response.data.error) {
        if (setError) setError(response.data.error);
        else if(Debug()) console.log(response.data.error);
        return;
      }

      if(Debug()) console.log(response)
      //MSFT doesnt use a refresh token so we pass the user as refresh in order to process expired tokens laters... should refactor but its hack time ot get ot release
      if (saveUser)
        saveUser({
          token: {
            type: type,
            id: token,
            refresh: refresh && refresh.current ? undefined : refresh,
          },
          scholarsift: response.data,
        });
      if (callBack) callBack(response.data);
    })
    .catch((error) => {
      if(Debug())console.log(error);
      if (error.response === undefined) return;
      if (error.response.status === 503) {
        if (setError) setError("Login Service not available");
      } else if (error.response.status === 500) console.log(error.response);
      else if (
        error.response.data !== undefined &&
        error.response.data !== null &&
        error.response.data.message !== undefined
      ) {
        console.log(error.response);
        if (setError) setError(error.response.data.message);
      } else if (error.response.status === 401) {
        if (error.response.data.toLowerCase().indexOf("expired") > -1) {
          console.log("Refresh...");
          RefreshToken(refresh, saveUser, callBack, count + 1);
        }
      } else {
        let keys = Object.keys(error);
        if(Debug())console.log(keys);
        keys.forEach((v) => console.log(error[v]));
      }
    });
};

export const testProcessToken = (user, callBack) => {
  axios
    .post(
      "/api/auth",
      { signon: user.current.type },
      {
        headers: {
          Authorization: "Bearer " + user.current.token.id,
        },
      }
    )
    .then((response) => {
      console.log(response);
      callBack(6, 3, response, "Process Token");
    });
};

export const ResetPassword = (email, setError) => {
  axios
    .post("/api/pwreset", { email: email })
    .then(() => {
      setError("Password Reset Email has been sent to: " + email);
    })
    .catch((err) => {
      if(Debug())console.log("Reset Password:");
      if(Debug())console.log(err);
    });
};

export const testUpdateLogin = (email, callBack) => {
  axios
    .post("/api/pwreset", { email: email })
    .then((response) => {
      callBack(6, 1, response, "Reset Password");
    })
    .catch((err) => {
      if(Debug()) console.log("Reset Password:");
      callBack(6, 1, err, "Reset Password");
    });
};

export const LocalCreateLogin = (
  email,
  password,
  first,
  last,
  setMessage,
  setError
) => {
  let data = {
    userId: email,
    email: email,
    userType: "customer",
    firstName: first,
    lastName: last,
    password: password,
    passwordConfirm: password,
  };

  axios
    .post("/api/create_local", data)
    .then((response) => {
      if (response.status === 200)
        setMessage("Account Successfully Created.  Awaiting Verification");
    })
    .catch((err) => {
      if (err.response && err.response.data) {
        setError(err.response.data);
        return;
      }

      if(Debug()) {
      console.log(err);
      console.log(err.response);
      console.log(err.response.data);
      }
    });
};

export const testCreateLogin = (callBack) => {
  axios
    .post("/api/create_local", {
      userId: "koyeary@gmail.com",
      email: "koyeary@gmail.com",
      userType: "customer",
      firstName: "Test",
      lastName: "Test",
      password: "12345678",
      passwordConfirm: "12345678",
    })
    .then((response) => {
      if(Debug())console.log(response.data);
      callBack(6, 2, response, "Create Login");
    })
    .catch((err) => {
      if(Debug())console.log(err);
      callBack(6, 2, err.response, "Create Login");
    });
};

export const LocalLogin = (
  email,
  password,
  saveUser,
  callBack,
  showLoader,
  setMessage,
  setError
) => {
  let data = {
    j_username: email,
    j_password: password,
    response_type: "code",
    client_id: creds.clientId,
    redirect_uri: "/api/redirect",
    user_type: "customer",
  };

  axios
    .post("/api/signon_local", data)
    .then((response) => {
      if(Debug())
        console.log(response)
      getLocalToken(
        response.data.code,
        data.client_id,
        data.redirect_uri,
        saveUser,
        callBack,
        showLoader,
        setMessage,
        setError
      );
    })
    .catch((err) => {
      console.log(err);
      console.log(err.request);
      console.log(err.response);
      if (err.request) {
        if (
          err.request.status === 404 &&
          err.request.responseURL.indexOf("/error") > -1
        )
          VerifyEmailAddress(email, setError);
        else if (err.request.status === 401) setError("Invalid login");
        else if (err.request.status === 500) {
          if (err.response && err.response.data && err.response.data.Error)
            setError(err.response.data.Error);
          else
            setError(
              <div>
                There was an error with the login system. Please try again in a
                few minutes or contact&nbsp;
                <a href="mailto:support@scholarsift.com">
                  support@scholarsift.com
                </a>{" "}
                for help.
              </div>
            );
        }
      }
    });
};

const getLocalToken = (
  code,
  client_id,
  redirect,
  saveUser,
  callBack,
  showLoader,
  setMessage,
  setError
) => {
  let data = {
    grant_type: "authorization_code",
    code: code,
    redirect_uri: redirect,
    headers: {
      Authorization: "Basic " + btoa(client_id + ":" + creds.clientSecret),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  axios
    .post("/api/token_local", data)
    .then((response) => {
      if(Debug())
        console.log(response)
      ProcessToken(
        response.data.access_token,
        response.data.refresh_token,
        "Local",
        saveUser,
        callBack,
        showLoader,
        setError
      );
    })
    .catch((err) => {
      if(Debug()) {
      console.log("getLocalToken Error: ");
      console.log(err);
      }
    });
};

export const VerifyEmailAddress = (email, setError) => {
  axios
    .post("/api/verify", { email: email })
    .then((response) => {
      if(Debug())console.log(response);
      if (response.data.email === undefined)
        setError(email + " is not a ScholarSift user login");
      else setError("Incorrect password");
    })
    .catch((err) => {
      if(Debug()) {console.log("Verify Email Address: ");
      console.log(err);
      }
    });
};

export const testVerifyEmail = (callBack) => {
  axios
    .post("/api/verify", { email: "supert@ucla.edu" })
    .then((response) => {
      if(Debug())console.log(response);
      callBack(6, 0, response, "Verify Accounts");
    })
    .catch((err) => {
     if(Debug()) { console.log("Verify Email Address: ");
      console.log(err.response);
     }
      callBack(6, 0, err, "Verify Accounts");
    });
};