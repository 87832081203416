import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useLocationState from "../../hooks/useLocationState";
import axios from 'axios';

const TopMargin = 200;

const PWReset = props => {
    const navigate = useNavigate();
    const location = useLocation();
    const [msg, setMsg] = useState();
    const [password, setPassword] = useState("");
    const [confirm, setConfirm] = useState("");
    const [error, setError] = useState({})
    const { state } = useLocationState();
    useEffect(() => {
        document.title = "ScholarSift - Password Reset";
    }) 

    const checkExec = e => {
        if(e.charCode === 13)
            Submit();
    }

    const checkPass = (v, key) => {
        let err = "";
        if(v === undefined || v.length === 0) {}
        else {
            if(key === "confirm")
                err = v !== password ? "" : "Passwords do not match";
            else if(v === undefined || v.length < 8)
                err = "Password must be at least 8 characters long";
        }
        if(key === "confirm")
            setConfirm(v);
        else if(key === "password")
            setPassword(v);
        if(err.length || Object.keys(error).length)
            setError({...error, [key]: err})
    }

    const Submit = () => {
        if((!msg || msg.length === 0) && password === confirm)
        {
            setMsg("Resetting Password... this may take a few seconds");
            return axios
                    .post("/api/reset", {email: location.state.email, id: location.state.id, "password": password, coda: location.state.coda})
                    .then(response => {
                        navigate("/signin", {state:{ email: location.state.email}})
                    })
                    .catch(err => {
                        console.log(err);
                        setMsg("Could not reset " +location.state.email + "'s password");
                    });
        }
    }

    return <div style={{minHeight: state.viewport.h - (state.viewport.m + state.viewport.f + TopMargin)}}>
            <div className="signon-panel" style={{top: state.viewport.m, marginTop: TopMargin}} >
                <div className="signon-holder">
                    <img src="/LOGO/ScholarSift-Logo_ReverseColor.png" alt="scholarsift logo" style={{width: 300}} />
                    <div className="signon-msg">Password Reset</div>
                    <div className="email-reset">{location.state.email}</div>
                    <input
                        borderless="true"
                        className="signon-input"
                        placeholder="Password"
                        required 
                        type="password"
                        errormessage={error["password"]}
                        value={password} 
                        onChange={e => { checkPass(e.target.value, "password"); }} 
                        onKeyDown={checkExec} 
                    />
                    <input 
                        borderless="true" 
                        className="signon-input" 
                        placeholder="Confirm Password" 
                        required 
                        type="password" 
                        errormessage={error["confirm"]} 
                        value={confirm} 
                        onChange={e => { checkPass(e.target.value, "confirm")}} 
                        onKeyDown={checkExec} 
                    />
                    <button className="signon-button" aria-label="Reset" onClick={Submit}>Reset Password</button>
                </div>
            </div>
      </div>
}

export default PWReset;