import { Debug } from "ss-lib";
import { APIErrorHandler } from "../../../actions/APIErrorHandler";
import axios from "axios";

export const LoadData = (user, saveUser, setGroups, setTemplates, setUsers, setDoneLoading) => {
  if(!user.current)
    return ;
  var ss = user.current.scholarsift;
  if(!ss)
    return ;
  var gid = ss.profile;
  if(ss.groupPrefs && ss.groupPrefs.length)
    gid = ss.groupPrefs[0].lid
  else if(ss.groups && ss.groups.length)
    gid = ss.groups[0].lid;
  var data = {
    domain: ss.email.substring(ss.email.indexOf("@") + 1),
    lid: gid
  }  
  axios
        .post("/api/lsgut", data,
          {
            headers: {
              Authorization: "Bearer " + user.current.token.id,
              Coda: user.current.scholarsift.coda,
            },
          }
        )
        .then((response) => {
          const { data } = response;
          if(Debug()) {
            console.log("RESPONSE")
            console.log(data);
          }
          setGroups(data.groups ? data.groups.map(v => ({...v["/submissions/manager"], id: v.id, lid: v.lid, journal:v.journal})) : []);
          setTemplates(data.templates ? data.templates : []);
          setUsers(data.users ? data.users : []);
          setDoneLoading(true);
        })
        .catch((err) => {
          APIErrorHandler(err, user, saveUser, (v) => { LoadData(user, saveUser, setGroups, setTemplates, setUsers, setDoneLoading); });
        });
}

export const UpdateData = (user, saveUser, data, callBack) => {
  axios
        .post("/api/ugut", data,
          {
            headers: {
              Authorization: "Bearer " + user.current.token.id,
              Coda: user.current.scholarsift.coda,
            },
          }
        )
        .then((response) => {
          const { data } = response;
          if(Debug()) {
            console.log("RESPONSE")
            console.log(data);
          }
          if(callBack)
            callBack(data);
        })
        .catch((err) => {
          APIErrorHandler(err, user, saveUser, (v) => { UpdateData(user, saveUser, data, callBack); });
        });
}