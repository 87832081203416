import { Navigate, useLocation } from "react-router-dom";
import useUser from '../../hooks/useUser';

const LawReviewRoute = ({children}) => {
  const { allowRoute } = useUser();
  const location = useLocation();

  console.log(allowRoute("lr"))
  if(!allowRoute("lr"))
    return <Navigate to='/signin' state={{location: location}} replace/>;
  return children;
}

export default LawReviewRoute;