import { useState, useRef, useEffect } from "react";
import useUser from "../../hooks/useUser";
import useLocationState from "../../hooks/useLocationState";
import ViewPort from "../Layout/ViewPort";
import { FontIcon } from "@fluentui/react";
import { searchFacultyBio } from "../../actions/Faculty";
import Trail from "../Layout/Animations/CardTrail";
import FacultyCard from "./FacultyCard";
import Loading from "../Layout/Loading";
import { useTracking } from "react-tracking";
import { Debug, ssToast } from "ss-lib";
import { Toast, useToastController } from "@fluentui/react-components";

const FacultyBios = () => {
  const [locationSearch, setLocationSearch] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [bios, setBios] = useState([]);
  const [open] = useState(true);
  const { state } = useLocationState();
  const { setTitle, user, saveUser } = useUser();
  const { dispatchToast } = useToastController(ssToast);

  const h = useRef(null);
  const containerRef = useRef(null);

  const { Track, trackEvent } = useTracking();
  const [data, setData] = useState({
    postalCode: "",
    searchRadius: 100,
    searchTerm: "",
  });

  const { postalCode, searchRadius, searchTerm } = data;

  useEffect(() => {
    document.title = "ScholarSift - Faculty Bios";
    setTitle("Faculty Bios");
    return () => {
      setTitle("");
    };
  });

  /*   const getZipFromIP = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    let postal = res.data.postal;
    if (postal === null) postal = "";
    trackEvent({ action: "detectLocation", data: res.data.postal });
    setData({ ...data, postalCode: postal });
    Debug("Location");
    Debug(postal);
  }; */

  /*   const getZip = (position) => {
    const lat = position.coords.latitude;
    const lng = position.coords.longitude;

    const latlng = { lat: lat, lng: lng };
    const geocoder = new window.google.maps.Geocoder();
    geocoder
      .geocode({ location: latlng })
      .then((res) => {
        setData({
          ...data,
          postalCode: res.results[0].address_components[6].long_name,
        });

        if (user.current) {
          let u = { ...user.current };
          u.scholarsift.postalCode =
            res.results[0].address_components[6].long_name;

          saveUser(u);
        }
      })
      .catch((err) => {
        window.alert(err);
      });
  }; */

  const handleChange = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const getResults = () => {
    if(Debug()) console.log("Search Term");
    if(Debug()) console.log(searchTerm);

    if(Debug()) console.log("Postal Code");
    if(Debug()) console.log(postalCode);

    let data = {
      text: searchTerm,
      postal: postalCode,
      searchRadius: searchRadius,
    };

    if(Debug()) console.log("Search Radius");
    if(Debug()) console.log(data.searchRadius);

    let intent = "error";
    data.text !== ""
      ? searchFacultyBio(
          data,
          setBios,
          setIsFetching,
          dispatchToast,
          setLocationSearch
        )
      : dispatchToast(<Toast>Search field cannot be empty</Toast>, {intent});
    return bios;
  };

  const handleClick = (e) => {
    e.preventDefault();

    getResults();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      getResults();
    }
  };

  const handleClear = (e) => {
    e.preventDefault();

    setBios([]);
    setLocationSearch(false);
    setData({ ...data, searchTerm: "", searchRadius: 100, postalCode: "" });
  };

  useEffect(() => {
    let v = state.viewport.h - (state.viewport.m + state.viewport.f);
    if (v !== h.current) h.current = v;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.viewport]);

  return (
    <ViewPort>
      <Track>
        <div
          style={{
            height: state.viewport.h,
          }}
          className="faculty-box"
        >
          <div className="faculty-grid">
            {/*  <div id="faculty-main"> */}

            <div className="faculty-search-box">
              <h1>
                Faculty Bios
                <FontIcon
                  iconName="ProfileSearch"
                  className="faculty-search-icon"
                  style={{ marginLeft: 10 }}
                />
              </h1>
              <div style={{ marginTop: 10, width: 500, maxWidth: "90%" }}>
                <input
                  className="basic-input"
                  name="searchTerm"
                  type="text"
                  id="faculty-input"
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  value={searchTerm}
                />

                <div
                  className="row"
                  style={{
                    width: 500,
                    maxWidth: "100%",
                    justifyContent: "center",
                    margin: "10px -10px auto auto",
                  }}
                >
                  {locationSearch && (
                    <div className="row range-row">
                      <div
                        className="row"
                        style={{
                          justifyContent: "center",
                          width: "fit-content",
                        }}
                      >
                        <div
                          className="basic-input-label"
                          style={{ color: "white" }}
                        >
                          Zip
                        </div>
                        <input
                          className="sm-input"
                          name="postalCode"
                          type="text"
                          id="postal-input"
                          placeholder="00000"
                          onChange={handleChange}
                          onKeyDown={handleKeyDown}
                          value={postalCode}
                        />
                      </div>
                      <div
                        className="row"
                        style={{
                          justifyContent: "center",
                          width: "fit-content",
                        }}
                      >
                        <div
                          className="basic-input-label"
                          style={{ color: "white" }}
                        >
                          Within
                        </div>
                        <input
                          className="sm-input"
                          name="searchRadius"
                          type="number"
                          id="range-input"
                          placeholder="Miles"
                          onChange={handleChange}
                          onKeyDown={handleKeyDown}
                          value={searchRadius}
                        />
                        <div
                          className="basic-input-label"
                          style={{ color: "white", marginLeft: -7 }}
                        >
                          miles
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="faculty-button-container">
                  <div
                    className="basic-input-label"
                    id="faculty-search-button"
                    onClick={handleClick}
                  >
                    Search
                  </div>
                  <div
                    className="basic-input-label"
                    id="faculty-search-button"
                    onClick={handleClear}
                  >
                    Clear
                  </div>
                </div>

                <div
                  className="row"
                  style={{
                    width: "fit-content",
                    justifyContent: "center",
                    margin: "auto",
                  }}
                >
                  <div
                    className="basic-input-label"
                    onClick={(e) => {
                      e.preventDefault();
                      setLocationSearch(!locationSearch);
                    }}
                    style={{
                      color: "white",
                      textAlign: "center",
                      margin: "-10px auto 10px auto",
                      cursor: "pointer",
                    }}
                  >
                    {locationSearch ? "Basic Search" : "Search by Location"}
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}
            <div className="faculty-column">
              <div className="faculty-card-list">
                <Trail open={open}>
                  {isFetching ? (
                    <Loading msg="Fetching faculty bios" />
                  ) : (
                    <>
                      {bios.map((f, index) => (
                        <FacultyCard f={f} key={index} index={index} />
                      ))}
                      <div
                        ref={containerRef}
                        key={containerRef}
                        style={{ height: 200 }}
                      />
                    </>
                  )}
                </Trail>
              </div>
            </div>
          </div>
        </div>
      </Track>
    </ViewPort>
  );
};

export default FacultyBios;
