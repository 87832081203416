import { APIErrorHandler } from '../../../../actions/APIErrorHandler';
import { Debug } from 'ss-lib';
import axios from 'axios';

export const fetchItems = async (user, saveUser, lid, setItems) => {
      axios
        .post("/api/lrsubmissions", {lid: lid},
          {
            headers: {
              Authorization: "Bearer " + user.current.token.id,
              Coda: user.current.scholarsift.coda,
            },
          }
        )
        .then((response) => {
          if (response.data.submissions) 
            setItems(response.data.submissions);
        })
        .catch((err) => {
          APIErrorHandler(err, user, saveUser, (v) => { fetchItems(user, saveUser, setItems); });
        });
}

export const fetchEditors = async (user, saveUser, lid, setEditors, toggleFetchingEditors) => { 
  axios
        .post("/api/lseditors", {lid: lid},
          {
            headers: {
              Authorization: "Bearer " + user.current.token.id,
              Coda: user.current.scholarsift.coda,
            },
          }
        )
        .then((response) => {
          if (response.data.editors)
            setEditors(response.data.editors);
          toggleFetchingEditors();
        })
        .catch((err) => {
          APIErrorHandler(err, user, saveUser, (v) => { fetchEditors(user, saveUser, lid, setEditors, toggleFetchingEditors); });
        });
}

export const fetchNMC = async (user, saveUser, setNMC) => {
  var p = user.current.scholarsift.profile;
  axios
      .post("/api/lsmsg", {profile: p},
        {
          headers: {
            Authorization: "Bearer " + user.current.token.id,
            Coda: user.current.scholarsift.coda,
          },
        }
      )
      .then((response) => {
          var arr = [];
          response.data.forEach(msg => {
              var key = msg.thread.startsWith(p + "-") ? "lend" : "pend";
              var nm = 0;
              for(var i = msg[key] + 1; i < msg.parts.length; i++) {
                  var h = JSON.parse(msg.parts[i].header)
                  if(h.from !== p) {
                      ++nm;
                      break;
                  }
              }
              arr.push({id: +msg.thread.substring(msg.thread.indexOf("-") + 1), ct: nm})
          })
          setNMC(arr);
      })
      .catch((err) => {
        APIErrorHandler(err, user, saveUser, () => { fetchNMC(user, saveUser, setNMC) });
      });
}

export const UpdatePagePrefs = (user, saveUser, data) => {
  axios
  .post("/api/upp", data,
    {
      headers: {
        Authorization: "Bearer " + user.current.token.id,
        Coda: user.current.scholarsift.coda,
      },
    }
  )
  .then((response) => {
      if(Debug())
        console.log(response.data);
  })
  .catch((err) => {
    APIErrorHandler(err, user, saveUser, () => { UpdatePagePrefs(user, saveUser, data) });
  });
}

export const UpdateGroupPrefs = (user, saveUser, data) => {
  axios
  .post("/api/ugp", data,
    {
      headers: {
        Authorization: "Bearer " + user.current.token.id,
        Coda: user.current.scholarsift.coda,
      },
    }
  )
  .then((response) => {
      if(Debug())
        console.log(response.data);
  })
  .catch((err) => {
    APIErrorHandler(err, user, saveUser, () => { UpdatePagePrefs(user, saveUser, data) });
  });
}


export const CreateSubmissionSet = (user, saveUser, data, callBack) => {
  axios
  .post("/api/css", data,
    {
      headers: {
        Authorization: "Bearer " + user.current.token.id,
        Coda: user.current.scholarsift.coda,
      },
    }
  )
  .then((response) => {
      callBack(response.data.id);
  })
  .catch((err) => {
    APIErrorHandler(err, user, saveUser, () => { CreateSubmissionSet(user, saveUser, data, callBack) });
  });
}

export const getSuppDoc = (user, saveUser, data, callBack) => {
  axios
      .post("/api/suppdoc", data, {
          headers : {
              "Authorization":"Bearer " + user.current.token.id,
              "Coda" :  user.current.scholarsift.coda
          },
          responseType: "blob",
      })
      .then(results => {
        let cd = results.headers["content-disposition"];
        if(cd) {
          let fn = cd.substring(cd.lastIndexOf("=") + 2);
          fn = fn.substring(0, fn.length - 1);
          const blob = new Blob([results.data], {
            type: results.headers["content-type"],
            size: results.headers["content-length"],
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fn);
          link.click();
          try {
            document.removeChild(link);
          } catch {
            try {
              link.parentNode.removeChild(link);
            } catch {}
          }
        }
        else {
          const blob = new Blob([results.data], {
            type: results.headers["content-type"],
            size: results.headers["content-length"],
          });
          blob.text().then(text => {
            const decodedText = text.replace(/\\u([\d\w]{4})/gi, (match, grp) => String.fromCharCode(parseInt(grp, 16)))
            callBack(decodedText.split("\n"))})
        }
      })
      .catch(err => {
        APIErrorHandler(err, user, saveUser, () => { getSuppDoc(user, saveUser, data, callBack) });
      })
}

export const AssignItems = (user, saveUser, data, callBack) => {
  axios
  .post("/api/assignments", data,
    {
      headers: {
        Authorization: "Bearer " + user.current.token.id,
        Coda: user.current.scholarsift.coda,
      },
    }
  )
  .then((response) => {
      if(Debug())
        console.log(response.data);
      if(callBack)
        callBack(response.data);
  })
  .catch((err) => {
    APIErrorHandler(err, user, saveUser, () => { UpdatePagePrefs(user, saveUser, data, callBack) });
  });
}

export const UpdateSubmissionStatus = (user, saveUser, data, callBack) => {
  axios
  .post("/api/uss", data,
    {
      headers: {
        Authorization: "Bearer " + user.current.token.id,
        Coda: user.current.scholarsift.coda,
      },
    }
  )
  .then((response) => {
      if(Debug())
        console.log(response.data);
      if(callBack)
        callBack(response.data);
  })
  .catch((err) => {
    APIErrorHandler(err, user, saveUser, () => { UpdatePagePrefs(user, saveUser, data, callBack) });
  });
}

export const UpdateSubmissionTags = (user, saveUser, data, callBack) => {
  axios
  .post("/api/ust", data,
    {
      headers: {
        Authorization: "Bearer " + user.current.token.id,
        Coda: user.current.scholarsift.coda,
      },
    }
  )
  .then((response) => {
      if(Debug())
        console.log(response.data);
      if(callBack)
        callBack(response.data);
  })
  .catch((err) => {
    APIErrorHandler(err, user, saveUser, () => { UpdatePagePrefs(user, saveUser, data, callBack) });
  });
}


export const UpdateSubmissionNotes = (user, saveUser, data, callBack) => {
  axios
  .post("/api/usn", data,
    {
      headers: {
        Authorization: "Bearer " + user.current.token.id,
        Coda: user.current.scholarsift.coda,
      },
    }
  )
  .then((response) => {
      if(Debug())
        console.log(response.data);
      if(callBack)
        callBack(response.data.newTags);
  })
  .catch((err) => {
    APIErrorHandler(err, user, saveUser, () => { UpdatePagePrefs(user, saveUser, data, callBack) });
  });
}

export const GetTemplate = (user, saveUser, id, callBack) => {
  axios
  .post("/api/template", {id: id},
    {
      headers: {
        Authorization: "Bearer " + user.current.token.id,
        Coda: user.current.scholarsift.coda,
      },
    }
  )
  .then((response) => {
      if(Debug())
        console.log(response.data);
      if(callBack)
        callBack(response.data);
  })
  .catch((err) => {
    APIErrorHandler(err, user, saveUser, () => { GetTemplate(user, saveUser, id, callBack) });
  });
}