import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useUser from '../../../hooks/useUser';
import useLocationState from '../../../hooks/useLocationState';
import ActiveUserSelector from '../ActiveUserSelector';
import {UserSubmissions as Submissions} from '../../Submissions';
import ViewPort from '../../Layout/ViewPort';
import { calcH } from '../../../utils';

const ManageSubmissions = props => {
    const location = useLocation();
    const { state } = useLocationState();
    const { setTitle } = useUser();
    
    useEffect(() => {
        document.title = "ScholarSift - Manage Submissions"
        setTitle("Manage Submissions");

        return () => { setTitle("") }
    }); 
    
    return  <ViewPort>
                <div style={{minHeight: calcH(state) + (state.viewport.o ? state.viewport.o : 0)}}>
            <ActiveUserSelector watch={true} modal={<Submissions 
                        name={state[location.pathname].current ? state[location.pathname].current.first + " " + state[location.pathname].current.last : undefined} 
                        account={state[location.pathname].current ? state[location.pathname].current.profile : undefined} 
                        selected={state[location.pathname].current}
                        management={true}
                    />} />
                     <Submissions 
                        name={state[location.pathname].current ? state[location.pathname].current.first + " " + state[location.pathname].current.last : undefined} 
                        account={state[location.pathname].current ? state[location.pathname].current.profile : undefined} 
                        selected={state[location.pathname].current}
                        management={true}
                    /> 
                </div>
            </ViewPort>
}

export default ManageSubmissions;