import { useState } from "react";
import { useAcceptJs } from "react-acceptjs";
//import ComboBox from "../Layout/ComboBox";
import TextInputModal from "../Layout/TextInputModal";
import creds from "../../creds.json";

import { createTransaction } from "../../actions/AuthNet";
import useUser from "../../hooks/useUser";
import useModal from "../../hooks/useModal";
import { getPlanType } from "./Plans";
import { Debug , ssToast } from "ss-lib";
import { Toast, useToastController } from "@fluentui/react-components";
//const environment = "SANDBOX";
const environment = "PRODUCTION";

const CheckoutForm = ({ plan, renew }) => {
  const { user, saveUser } = useUser();
  const { dispatchToast } = useToastController(ssToast);
  const { hideModal } = useModal();
  const [planType] = useState(getPlanType(user));

  let index =
    user &&
    user.current &&
    user.current.scholarsift &&
    user.current.scholarsift.name
      ? user.current.scholarsift.name.lastIndexOf(" ")
      : -1;
  let fn = index > -1 ? user.current.scholarsift.name.substr(0, index) : "";
  let ln = index > -1 ? user.current.scholarsift.name.substr(index + 1) : "";
  const [formData, setFormData] = useState({
    firstName: fn,
    lastName: ln,
    cardNumber: "",
    cardCode: "",
    month: "",
    year: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "US",
    amount: plan.types[planType],
    email: user.current.scholarsift.email,
  });

  const authData = {
    apiLoginID: creds.authorize.apiLogin,
    clientKey: creds.authorize.publicKey,
  };

  /*   const authData = {
    apiLoginID: creds.authorize.testApiLogin,
    clientKey: creds.authorize.testPublicKey,
  }; */

  const { dispatchData } = useAcceptJs({
    environment,
    authData,
  });

  const {
    firstName,
    lastName,
    cardNumber,
    month,
    year,
    cardCode,
    email,
    address,
    city,
    country,
    state,
    zip,
    amount,
  } = formData;

  const onChange = (e) => {
    console.log(amount);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const cardData = { cardNumber, cardCode, month: month, year: year };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await dispatchData({ cardData });

      if(Debug()) console.log("Received response: ");
      if(Debug()) console.log(res);

      const opaqueData = res.opaqueData;

      const finishPurchase = () => {
        hideModal();
        let intent = "info"
        dispatchToast(<Toast  appearance='inverted'>Purchase successful:  + plan.name</Toast>, {intent});
      };

      createTransaction(
        user,
        saveUser,
        plan,
        renew,
        formData,
        opaqueData,
        dispatchToast,
        finishPurchase
      );
    } catch (err) {
      console.error(err);
      let intent = "error"
      dispatchToast(<Toast appearance='inverted'>Purchase failed</Toast>, {intent});
      //send alert to api
    }
  };

  return (
    <div className="checkout-modal">
      <div className="checkout-title">Checkout</div>
      <form onSubmit={handleSubmit}>
        <div className="form-body">
          <div className="form-box">
            <TextInputModal
              styles="modal-input-label-dark"
              id="firstName"
              label="First name"
              placeholder="Your first name"
              error={false}
              value={firstName}
              name="firstName"
              type="text"
              onChange={onChange}
            />
            <TextInputModal
              styles="modal-input-label-dark"
              id="lastName"
              label="Last name"
              placeholder="Your last name"
              error={false}
              value={lastName}
              name="lastName"
              type="text"
              onChange={onChange}
            />

            <TextInputModal
              styles="modal-input-label-dark"
              id="cardNum"
              label="Card Number"
              placeholder="4242424242424242"
              error={false}
              value={cardNumber}
              name="cardNumber"
              type="text"
              onChange={onChange}
            />
            <TextInputModal
              styles="modal-input-label-dark"
              id="cVV"
              label="CVV"
              placeholder="123"
              error={false}
              name="cardCode"
              type="text"
              value={cardCode}
              onChange={onChange}
            />
            <TextInputModal
              styles="modal-input-label-dark"
              id="expMo"
              label="Exp Month"
              name="month"
              type="text"
              placeholder="MM"
              error={false}
              value={month}
              onChange={onChange}
            />
            <TextInputModal
              styles="modal-input-label-dark"
              id="expYe"
              label="Exp Year"
              name="year"
              type="text"
              placeholder="YYYY"
              error={false}
              value={year}
              onChange={onChange}
            />
          </div>
          <div className="form-box form-box-right">
            <TextInputModal
              styles="modal-input-label-dark"
              label="Email"
              name="email"
              placeholder="Your email"
              type="email"
              id="eMail"
              value={email}
              error={false}
              onChange={onChange}
            />
            <TextInputModal
              styles="modal-input-label-dark"
              label="Address"
              name="address"
              id="add"
              placeholder="Your address"
              type="text"
              error={false}
              value={address}
              onChange={onChange}
            />
            <TextInputModal
              styles="modal-input-label-dark"
              id="city"
              label="City"
              name="city"
              placeholder="City"
              type="text"
              error={false}
              value={city}
              onChange={onChange}
            />
            <TextInputModal
              styles="modal-input-label-dark"
              id="modal-input-label-dark"
              label="Country"
              placeholder="Country"
              error={false}
              value={country}
              name="country"
              type="text"
              onChange={onChange}
            />
            {country === "US" || country === "United States" ? (
              <>
                <TextInputModal
                  styles="modal-input-label-dark"
                  id="modal-input-label-dark"
                  label="State"
                  placeholder="State"
                  error={false}
                  value={state}
                  name="state"
                  type="text"
                  onChange={onChange}
                />
                <TextInputModal
                  styles="modal-input-label-dark"
                  id="zip"
                  label="Zip code"
                  name="zip"
                  type="text"
                  placeholder="Zip"
                  error={false}
                  value={zip}
                  onChange={onChange}
                />
              </>
            ) : null}
          </div>
        </div>
        <div className="checkout-footer">
          <button
            className="form-btn"
            type="button"
            id="cancel"
            onClick={hideModal}
          >
            Cancel
          </button>
          <button
            className="form-btn"
            type="submit"
            errormessage="Errors in form"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default CheckoutForm;
