import { useMemo } from 'react'
import { useNavigate } from "react-router-dom";
import { useBoolean } from "@fluentui/react-hooks";
import { Panel, Nav, Stack, Rectangle } from "@fluentui/react";
import {
  GlobalNavButtonIcon,
  LeaveUserIcon,
  ContactIcon,
} from "@fluentui/react-icons-mdl2";
import useUser from "../../hooks/useUser";
import RectButton from "../Layout/Buttons/RectButton";
import "./Nav.scss";
import { Debug } from 'ss-lib';
import { CounterBadge } from '@fluentui/react-components';

const MenuPanel = ({
  isAuth,
  isAdmin,
  isLawReview,
  isGroupAdmin,
  type,
  signOut,
  user,
  isCollapsed,
  darkMode,
}) => {
  const navigate = useNavigate();
  const { allowRoute, nMessages, isJournalManager } = useUser();
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);

  const handleOpen = (e) => {
    e.preventDefault();
    openPanel();
  };

  const navStyle = {
    linkText: { fontSize: 16 },
  };

  const navGroups = useMemo(() => {
    let navItems = [];
    var all = [
        {
          key: "welcome",
          name: "Welcome",
          icon: "Home",
          forceAnchor: true,
          onClick: () => {
            dismissPanel();
            navigate("/welcome");
          },
        },
        {
          key:"messages",
          name: nMessages ? "Messages (" + nMessages + ")": "Messages",
          icon:"Mail",
          forceAnchor: true,
          onClick: () => {
            dismissPanel();
            navigate("/messages");
          }
        },
        {
          name: "Analyze",
          key: "analyze",
          icon: "DiagnosticDataBarTooltip",
          forceAnchor: true,
          onClick: () => {
            dismissPanel();
            navigate("/analyze");
          },
        },
        {
          key: "papers",
          name: "My Papers",
          icon: "PageEdit",
          forceAnchor: true,
          onClick: () => {
            dismissPanel();
            navigate("/papers");
          },
        },
        {
          name: "Faculty Bios",
          key: "Faculty Bios1",
          icon: "ProfileSearch",
          forceAnchor: true,
          onClick: () => {
            dismissPanel();
            navigate("/faculty");
          },
        },
        {
          name: "Profile",
          key: "profile",
          icon: "AccountManagement",
          forceAnchor: true,
          onClick: () => {
              dismissPanel();
              navigate("/profile");
          },
        },
      ]
      navItems.push({
        collapseByDefault: false,
        links: all,
      });

      if(allowRoute("lr")) {
        var journalLinks = [
          {
            name: "Submission Manager",
            key: "submissionmgr",
            icon: "TaskManager",
            forceAnchor: true,
            onClick: () => {
              dismissPanel();
              navigate("/submissions/manager");
            },
          },
          {
            name: "Bulk Upload",
            key: "bulkupload",
            icon: "BulkUpload",
            forceAnchor: true,
            onClick: () => {
              dismissPanel();
              navigate("/submissions/upload");
            },
          }
        ]

        if(isJournalManager)
          journalLinks.push({
            name: "Journal Manager",
            key: "journalmgr",
            icon: "People",
            forceAnchor: true,
            onClick: () => {
              dismissPanel();
              navigate("/journal/manager");
            },
          });
        
        navItems.push({
          name: "Journal Submissions",
          expandAriaLabel: "Journal Submission Menu",
          collapseAriaLabel: "Collapse Journal Submission Menu",
          collapseByDefault: false,
          links: journalLinks,
        });
      }
      
      var mySubmissionLinks = [
        {
          name: "Submit a Manuscript",
          key: "submit",
          icon: "Upload",
          forceAnchor: true,
          onClick: () => {
            dismissPanel();
            navigate("/submit");
          },
        },
        {
          name: "Manage",
          key: "my_submissions",
          icon: "TaskManager",
          forceAnchor: true,
          onClick: () => {
            dismissPanel();
            navigate("/submissions");
          },
        }
      ]

      navItems.push({
        name: "My Submissions",
        expandAriaLabel: "Manuscript Submission Menu",
        collapseAriaLabel: "Collapse Manuscript Submission Menu",
        collapseByDefault: false,
        links: mySubmissionLinks,
      });

      if (isAdmin) {
        navItems.push({
          name: "Admin Menu",
          expandAriaLabel: "Expand Admin menu section",
          collapseAriaLabel: "Collapse Admin menu section",
          collapseByDefault: false,
          links: [
            {
              name: "Dashboard",
              expandAriaLabel: "Expand Admin dashboard section",
              collapseAriaLabel: "Collapse Admin dashboard section",
              collapseByDefault: false,
              links: [
                {
                  key: "accounts2",
                  name: "Accounts",
                  icon: "ProfileSearch",
                  forceAnchor: true,
                  onClick: () => {
                    dismissPanel();
                    navigate("/dashboard/accounts");
                  },
                },
                {
                  key: "crm2",
                  name: "CRM",
                  icon: "ProfileSearch",
                  forceAnchor: true,
                  onClick: () => {
                    dismissPanel();
                    navigate("/dashboard/crm");
                  },
                },
                {
                  key: "groups2",
                  name: "Groups",
                  icon: "Group",
                  forceAnchor: true,
                  onClick: () => {
                    dismissPanel();
                    navigate("/dashboard/groups");
                  },
                },
                {
                  key: "journals2",
                  name: "Journals",
                  icon: "Articles",
                  forceAnchor: true,
                  onClick: () => {
                    dismissPanel();
                    navigate("/dashboard/journals");
                  },
                },
                {
                  key: "maintenance2",
                  name: "Maintenance",
                  icon: "ConstructionCone",
                  forceAnchor: true,
                  onClick: () => {
                    dismissPanel();
                    navigate("/dashboard/maintenance");
                  },
                },
                {
                  key: "usage2",
                  name: "Usage",
                  icon: "AnalyticsReport",
                  forceAnchor: true,
                  onClick: () => {
                    dismissPanel();
                    navigate("/dashboard/usage");
                  },
                },
                {
                  key: "system-health2",
                  name: "System Health",
                  icon: "SyncStatus",
                  forceAnchor: true,
                  onClick: () => {
                    dismissPanel();
                    navigate("/dashboard/system&health");
                  },
                },
              ],
            },
            {
              key: "bugs2",
              name: "Bugs",
              forceAnchor: true,
              icon: "Bug",
              onClick: () => {
                dismissPanel();
                navigate("/bugs");
              },
            },
            {
              key: "nodes2",
              name: "Nodes",
              icon: "BranchFork2",
              forceAnchor: true,
              onClick: () => {
                dismissPanel();
                navigate("/nodes");
              },
            },
          ],
        });
      }

      var infoLinks = [    
        {
          key: "about",
          name: "About",
          icon: "Info",
          forceAnchor: true,
          onClick: () => {
            dismissPanel();
            navigate("/about");
          },
        },
        {
          key: "contact",
          name: "Contact",
          icon: "ContactCard",
          forceAnchor: true,
          onClick: () => {
            dismissPanel();
            navigate("/contact");
          },
        },
        {
          name: "Tutorials",
          key: "tutorials",
          icon: "D365TalentLearn",
          forceAnchor: true,
          onClick: () => {
            dismissPanel();
            navigate("/tutorials");
          },
        },
        {
          name: "FAQs",
          key: "faqs",
          icon: "StatusCircleQuestionMark",
          forceAnchor: true,
          onClick: () => {
            dismissPanel();
            navigate("/faqs");
          },
        },
      ]

      navItems.push({
        name: "Info",
        expandAriaLabel: "Information Menu",
        collapseAriaLabel: "Collapse Information Menu",
        collapseByDefault: false,
        links: infoLinks,
      });
      
      return navItems;
      /*
    if (!isLawReview && !isAdmin && !isGroupAdmin && !isJunior && isAuth) {
      navItems.push({
        name: "Subscription",
        expandAriaLabel: "Expand Admin menu section",
        collapseAriaLabel: "Collapse Admin menu section",
        collapseByDefault: true,
        links: [
          {
            key: "payment",
            icon: "UserFollowed",
            name: getPaid,
            forceAnchor: true,
            onClick: () => {
              dismissPanel();
              navigate("/subscribe");
            },
          },
        ],
      });
    }
      */
  }, [dismissPanel, isAdmin, isJournalManager, isLawReview, nMessages, navigate]);

  const signInNav = () => {
    dismissPanel();
    navigate("/signin");
  };

  return (
    <>
      {isAuth && (
        <div className="collapse-nav">
          <div
            className="nav "
            aria-label="Open side menu"
            onClick={handleOpen}
          >
            <GlobalNavButtonIcon />{nMessages > 0 && <CounterBadge style={{marginRight: -10, marginTop: -10}} count={nMessages} color='danger' size='small' />}
          </div>
        </div>
      )}

      <div>
        <div
          className="global-nav-icon"
          id={darkMode ? "gni-dark" : undefined}
          aria-label="Open side menu"
          onClick={handleOpen}
        >
          <GlobalNavButtonIcon />
        </div>
      </div>
      <Panel
        isLightDismiss
        eventBubblingEnabled={true}
        isOpen={isOpen}
        onDismiss={dismissPanel}
        headerText="Menu"
        dismissPanel={true}
        headerTextProps={{
          style: darkMode
            ? {
                fontSize: 32,
                padding: 10,
                fontFamily: "'Outfit', sans-serif",
                color: "#FFF",
              }
            : {
                fontSize: 32,
                padding: 10,
                fontFamily: "'Outfit', sans-serif",
                color: "#4061d6",
              },
        }}
        closeButtonAriaLabel="Close"
        type={1}
      >
        <Nav groups={navGroups} styles={navStyle} selectedKey={null} />
        <Stack horizontal horizontalAlign="center" style={{ margin: 20 }}>
          {user.scholarsift.profile ? (
            <RectButton
              onClick={() => {
                signOut();
                dismissPanel();
              }}
              title="Sign Out"
              icon={<LeaveUserIcon style={{ marginLeft: 10 }} />}
            />
          ) : (
            <RectButton
              onClick={() => {
                signInNav();
                dismissPanel();
              }}
              title="Sign In"
              icon={<ContactIcon style={{ marginLeft: 10 }} />}
            />
          )}
        </Stack>
      </Panel>
    </>
  );
};

export default MenuPanel;
