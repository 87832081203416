import { useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import useUser from "../../hooks/useUser";
import { cancelSubscription, updateSubscription } from "../../actions/AuthNet";
import useAlert from "../../hooks/useAlert";
import { Debug, ssToast  } from "ss-lib";
import { ONE_DAY_IN_MS } from "@azure/msal-common";
import { planObjects } from "./Plans";
import { Toast, useToastController } from "@fluentui/react-components";

const getTimeDiff = (user) => {
  let ss = user.current.scholarsift;
  return ss.expiration
    ? (ss.expiration - new Date().getTime()) / ONE_DAY_IN_MS
    : 0;
};

const printDays = (days) => {
  let v = Math.floor(days);
  let r = v + " days ";
  let h = 24 * (days - v);
  v = Math.floor(h);
  r += v + " hours ";
  let m = 60 * (h - v);
  return r + Math.round(m) + " minutes";
};

const getRemaining = (diff) => {
  if (diff > 0) return printDays(diff);
  return "has expired.";
};

const getDetails = (user) => {
  let ss = user.current.scholarsift;
  const { subscription } = ss;

  let details = {};
  if (
    subscription &&
    (typeof subscription === "string" || subscription instanceof String)
  ) {
    details.name = subscription;
    details.renews = subscription.indexOf("Auto Renews") > -1;
  } else {
    details.name = "Plan";
    details.renews = false;
  }
  details.timeDiff = getTimeDiff(user);
  details.expired = details.timeDiff <= 0;
  details.remaining = getRemaining(details.timeDiff);
  return details;
};

const PlanDetails = (props) => {
  const { user, saveUser } = useUser();
  const [details] = useState(getDetails(user));
  const { showAlert, hideAlert } = useAlert();
  const { dispatchToast } = useToastController(ssToast);

  if(Debug()) console.log(user);
  if(Debug()) console.log(details);

  const confirmRenew = () => {
    let key = details.name.indexOf("Year") > -1 ? "Year" : "Month";
    const plan = planObjects.find((v) => v.name.indexOf(key) > -1);
    if(Debug()) console.log(plan);
    if (plan === undefined) return;

    showAlert(
      <>
        <div className="alert-body">
          You are about to upgrade to {plan.name} (Auto Renews).<br></br>
          Hit 'Ok' to confirm.
        </div>
        <div className="alert-footer">
          <div
            className="alert-button"
            id="dismiss-alert"
            onClick={() => hideAlert()}
          >
            Go back
          </div>
          <div
            className="alert-button"
            onClick={() =>
              updateSubscription(user, saveUser, plan, dispatchToast, () => {
                let intent = "info";
                dispatchToast(
                  <Toast appearance='inverted'>Your subscription has been upgraded to Auto Renew</Toast>,
                  {intent}
                );
              })
            }
          >
            Ok
          </div>
        </div>
      </>
    );
  };

  const handleCancel = (e) => {
    e.preventDefault();
    showAlert(
      <>
        <div className="alert-body">Are you sure you want to cancel?</div>
        <div className="alert-footer">
          <div
            className="alert-button"
            id="dismiss-alert"
            onClick={() => hideAlert()}
          >
            Go back
          </div>
          <div
            className="alert-button"
            onClick={() =>
              cancelSubscription(user, saveUser, dispatchToast, () => {
                let intent = "info";
                dispatchToast(
                  <Toast>Your subscription has been cancelled. You may continue to use it until the end of your billing period.</Toast>,
                  { intent }
                );
              })
            }
          >
            Cancel
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="payment-options-contianer">
      <div className="basic-card subscription-card">
        <div className="payment-detail-title">{details.name}</div>
        <div className="payment-detail-info">
          Your {details.name}{" "}
          {details.expired
            ? "expired."
            : (details.renews ? "" : "expires") + " in " + details.remaining}
        </div>
        {details.renews ? (
          <>
            <div className="payment-detail-info">
              Need to cancel your subscription?
            </div>
            <div className="form-btn" onClick={handleCancel}>
              Cancel Subscription
            </div>
          </>
        ) : (
          <>
            <div className="payment-detail-info">
              <div>Want to switch to Auto-Renew?</div>
            </div>
            <div className="form-btn" onClick={confirmRenew}>
              Auto-Renew
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PlanDetails;
