import { useEffect } from "react";
import { useNavigate } from "react-router";
import { Card, CardHeader } from '@fluentui/react-components'
import { Toast, ToastBody, ToastTitle, useToastController } from "@fluentui/react-components";
import ViewPort from "../Layout/ViewPort";
import useLocationState from "../../hooks/useLocationState";
import { Landing, landingMargin, sentenceCase, useProfile, ssToast } from 'ss-lib';
import { PaperPage } from "../Papers";
import useUser from "../../hooks/useUser";
import {ProfileParent} from "../Profile/";
import Analyze from "../Analyze";
import { isJournalUser } from '../../utils';
import { GetMessageCount } from "./actions";
import { SubmissionPortalComponent } from "../SubmissionPortal/Manager";
import { MessagesComponent } from '../Messages';

const getID = (e) => {
    let t = e.target;
    if(t.id.length)
        return t.id;
    while(t.parentElement) {
        t = t.parentElement;
        if(t.id.length)
            return t.id;
    }
    return undefined;
}


export const LandingPage = props => {
    const profile = useProfile();
    const { state } = useLocationState();
    const { user, saveUser, setTitle, setNMessages } = useUser();
    const navigate = useNavigate();
    const { dispatchToast } = useToastController(ssToast);

    useEffect(() => {
        setTitle("Welcome");
        window.document.title = "ScholarSift - Welcome Page";
        if(user.current.scholarsift.public === undefined)
            navigate("/signin") ;
        let elmt = document.getElementById("landingTop");
        if(elmt)
            elmt.focus();
        if(user.current && user.current.scholarsift && user.current.scholarsift.incomplete && user.current.scholarsift.incomplete.length > 0 && user.current.scholarsift.warning === undefined) {
            dispatchToast(<Toast appearance='inverted'><ToastTitle>Notification</ToastTitle><ToastBody>You have {user.current.scholarsift.incomplete.length} incomplete upload{user.current.scholarsift.incomplete.length > 1 ? "s":""}</ToastBody></Toast>, {intent: "warning"}) 
            saveUser({...user.current, scholarsift: {...user.current.scholarsift, warning: true}});
        }
    })

    useEffect(() => {
        if(window.location.pathname === "/welcome")
            GetMessageCount(user, saveUser, setNMessages);
    }, [window.location.pathname])

    const OpenClick = (v, state) => {
        navigate(v, {state:state});
    }

    const onDrop = e => {
        var f = e.dataTransfer.files[0];
        navigate(e.target.innerHTML.indexOf("analyze") === -1 ? "/papers" : "/analyze", {state: {file:f, items:user.current.scholarsift.papers}})
    }

    const updateTopics = (topic) => {
        console.log(topic)
    }

    if(user.current.scholarsift.public === undefined)
        return ;

    const hVal = props.height ? props.height : state.viewport.h ? (state.viewport.h - (state.viewport.m + state.viewport.f + (2 * landingMargin))) : "85vh";
    return  <ViewPort>
                <div id="top" style={{margin: landingMargin, height: window.innerWidth > 1024 ? hVal : undefined}}>
                {
                    isJournalUser(user.current) ? 
                    <Landing complete={profile.complete} announcements={user.current.scholarsift.announcements} topics={!user.current.scholarsift.suggestions ? [] : user.current.scholarsift.suggestions.map(j => {
                        let k = Object.keys(j)[0];
                        return {
                            topic: sentenceCase(k),
                            items: j[k],
                            profile:user.current.scholarsift.profile,
                        };
                    })}>
                        <div
                            id="landingTop"
                            style={{margin: landingMargin, display:"flex", height: "calc(100% - " +  landingMargin * 2  + "px)", width: "calc(100% - " +  landingMargin * 2  + "px)"}} 
                            onClick={e => {e.stopPropagation(); OpenClick("/submissions/manager", {id: getID(e)}) }}
                        >
                            <Card style={{minWidth:"100%"}}>
                                <CardHeader header={<div style={{fontWeight: 600, lineHeight: window.innerWidth < 600 ? 1.1 : undefined, fontSize: "2rem", fontFamily: "var(--Serif)", color:"white", backgroundColor: "var(--blue)", borderRadius: 5, boxShadow: "0px 10px 10px 0px lightgray", padding:15, marginLeft:"auto", marginRight:"auto"}}>Submissions</div>}/>
                                <SubmissionPortalComponent height={hVal} showControls={false}/>
                                </Card>
                        </div> 
                        <div
                            style={{margin: landingMargin, display:"flex", height: "calc(100% - " +  landingMargin * 2  + "px)", width: "calc(100% - " +  landingMargin * 2  + "px)"}}
                            onDragEnter={(e) => e.preventDefault()}
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={(e) => {e.stopPropagation(); onDrop(e)}}
                            onClick={e => {e.stopPropagation(); OpenClick("/analyze", {id: getID(e)})}}
                        >
                            <Card style={{minWidth:"100%"}}>
                                <CardHeader header={<div style={{fontWeight: 600, fontSize: "2rem", fontFamily: "var(--Serif)", color:"white", backgroundColor: "var(--blue)", borderRadius: 5, boxShadow: "0px 10px 10px 0px lightgray", padding:15, marginLeft:"auto", marginRight: "auto"}}>Analyze</div>}/>
                                <Analyze preview={true} />
                            </Card>
                        </div>
                        <div
                            style={{margin: landingMargin, display:"flex", height: "calc(100% - " +  landingMargin * 2  + "px)", width: "calc(100% - " +  landingMargin * 2  + "px)"}}
                            onClick={e => {e.stopPropagation(); OpenClick("/messages", {part: getID(e)})}}
                        >
                            <Card style={{minWidth:"100%"}}>
                                <CardHeader header={<div style={{fontWeight: 600, fontSize: "2rem", fontFamily: "var(--Serif)", color:"white", backgroundColor: "var(--blue)", borderRadius: 5, boxShadow: "0px 10px 10px 0px lightgray", padding:15, marginLeft:"auto", marginRight: "auto"}}>Messages</div>}/>
                                <MessagesComponent />
                            </Card>
                        </div>
                    </Landing> :
                    <Landing complete={profile.complete} announcements={user.current.scholarsift.announcements} topics={!user.current.scholarsift.suggestions ? [] : user.current.scholarsift.suggestions.map(j => {
                        let k = Object.keys(j)[0];
                        return {
                            topic: sentenceCase(k),
                            items: j[k],
                            profile:user.current.scholarsift.profile,
                        };
                    })}>        
                        <div
                            id="landingTop"
                            style={{margin: landingMargin, display:"flex", height: "calc(100% - " +  landingMargin * 2  + "px)", width: "calc(100% - " +  landingMargin * 2  + "px)"}} 
                            onDragEnter={(e) => e.preventDefault()}
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={(e) => {e.stopPropagation(); onDrop(e)}}
                            onClick={e => {e.stopPropagation(); OpenClick("/papers", {items:user.current.scholarsift.papers, id: getID(e)}) }}
                        >
                            <Card style={{minWidth:"100%"}}>
                                <CardHeader header={<div style={{fontWeight: 600, lineHeight: window.innerWidth < 600 ? 1.1 : undefined, fontSize: "2rem", fontFamily: "var(--Serif)", color:"white", backgroundColor: "var(--blue)", borderRadius: 5, boxShadow: "0px 10px 10px 0px lightgray", padding:15, marginLeft:"auto", marginRight:"auto"}}>My Preprints & Papers</div>}/>
                                <PaperPage incomplete={user.current && user.current.scholarsift ? user.current.scholarsift.incomplete : undefined} items={user.current.scholarsift.papers} stats={user.current.scholarsift.pinfo ? user.current.scholarsift.pinfo.stats : []} preview={true}/>
                            </Card>
                        </div>
                        <div onClick={e => {e.preventDefault(); OpenClick("/profile", {pdata:user.current.scholarsift.pinfo ? user.current.scholarsift.info : {name: user.current.scholarsift.name}, id: getID(e)}) }} style={{padding:7.5}}>
                            <Card>
                                <CardHeader header={<div style={{fontWeight: 600, fontSize: "2rem", fontFamily: "var(--Serif)", color:"white", backgroundColor: "var(--blue)", borderRadius: 5, boxShadow: "0px 10px 10px 0px lightgray", padding:15, marginLeft:"auto", marginRight:"auto"}}>Profile</div>}/>
                                <ProfileParent pdata={user.current.scholarsift.pinfo ? user.current.scholarsift.pinfo : {name: user.current.scholarsift.name}} />
                            </Card>
                        </div>
                        <div
                            style={{margin: landingMargin, display:"flex", height: "calc(100% - " +  landingMargin * 2  + "px)", width: "calc(100% - " +  landingMargin * 2  + "px)"}}
                            onDragEnter={(e) => e.preventDefault()}
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={(e) => {e.stopPropagation(); onDrop(e)}}
                            onClick={e => {e.stopPropagation(); OpenClick("/analyze", {id: getID(e)})}}
                        >
                            <Card style={{minWidth:"100%"}}>
                                <CardHeader header={<div style={{fontWeight: 600, fontSize: "2rem", fontFamily: "var(--Serif)", color:"white", backgroundColor: "var(--blue)", borderRadius: 5, boxShadow: "0px 10px 10px 0px lightgray", padding:15, marginLeft:"auto", marginRight: "auto"}}>Analyze</div>}/>
                                <Analyze preview={true} />
                            </Card>
                        </div>
                    </Landing>
                }
                </div>
            </ViewPort>
}