import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Toast, ToastTitle, ToastBody, useToastController } from '@fluentui/react-components';
import ViewPort from '../Layout/ViewPort';
import { LoadingAnimation, ManageUserSubmissions, ssToast } from 'ss-lib';
import useUser from '../../hooks/useUser';
import useLocationState from '../../hooks/useLocationState';
import { fetchItems, ListJournals, SubmitData, UpdateFile } from './actions';
import { fetchNMC, getSuppDoc } from '../SubmissionPortal/Manager/actions';

const UserSubmissionPortalComponent = props => {
    const [dialogData, setDialogData] = useState();
    const [items, setItems] = useState();
    const [fetchingItems, setFetchingItems] = useState(false);
    const [nmc, setNMC] = useState();
    const [fetchingNMC, setFetchingNMC] = useState(false);
    const [itemCS, setItemCS] = useState(false);
    const [journals, setJournals] = useState();
    const [journalNames, setJournalNames] = useState();
    const location = useLocation();
    const navigate = useNavigate();
    const { user, saveUser, setTitle } = useUser();
    const { dispatchToast } = useToastController(ssToast);
   
    useEffect(() => {
        setTitle("Manage My Submissions");
        document.title = "Manage My Submissions";
    })

    useEffect(() => {
        if(items === undefined && !fetchingItems) {
                    setFetchingItems(true);
                    fetchItems(user, saveUser, (v) => {
                        setItems(v);
                        setFetchingItems(false)
                    })
                }
                if(nmc === undefined && !fetchingNMC) {
                    setFetchingNMC(true);
                    fetchNMC(user, saveUser, (v) => {setNMC(v); setFetchingNMC(false)})
                }
    }, [location.pathname])

    useEffect(() => {
        if(!itemCS && items && nmc) {
            var itms = [...items];
            itms.forEach(itm => {
                var nm = nmc.find(nm => nm.id === +itm.id);
                itm.messages = nm ? nm.ct : 0;
            })
            setItems(itms);
            setItemCS(true);
        }
    }, [items, nmc])

    const GetJournals = (item, callBack) => {
        ListJournals(user, saveUser, item.mtype, (j) => {
            var lc = item.mtype.toLowerCase();
            var sets = j.sets.filter(v => !v.internal || user.current.scholarsift.groupPrefs.find(u => u.lid === v.lid))
            var js = j.journals.map(v => ({[v.name] : {[lc]: {...v.settings[lc], sets: sets.filter(s => v.id === s.lid)}}}))
            var submitted = items.filter(v => v.id === item.id).map(v => v.name);
            var jj = js.filter(v => {
                var key = Object.keys(v)[0]
                return v[key][lc].sets && v[key][lc].sets.length && submitted.find(vv => vv === key) === undefined
            })
            setJournalNames(jj.map(v => {
                var journal = j.journals.find(vv => vv.name === Object.keys(v)[0])
                return {name: journal.name, id: journal.id}
            }))
            setJournals(jj);
            if(callBack)
                callBack()
        });
    }

    const SendFile = (md, callBack) => {
        let input = document.createElement("input");
        input.type = "file";
        input.className = "basic-input";
        input.accept = ".doc,.docx,.pdf,.txt,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,text/plain";
        input.onchange = (e) => {
            UpdateFile(user, saveUser, e.target.files[0], md, callBack);
        };
        input.click();
    }

    const handleItemClick = v => {
        const { key, item, replace } = v;
        
        //Check for replace
        if(replace || key === "update") {
            var id = item.id === undefined ? +item.substring(0, item.indexOf(".")) : item.id;
            var parts = item.id === undefined ? item.split("."): undefined;
            SendFile({id: id, lid: (item.id === undefined ? +item.substring(item.indexOf(".") + 1, item.lastIndexOf(".")) : undefined), key: key}, v => {
                if(key !== "update")
                {
                    var itm = items.find(vv => vv.id === id);
                    if(key === "cover") {
                        var ids = item[key];
                        var s = new Set();
                        if(Array.isArray(ids))
                            s = new Set(ids);
                        item[key] = Array.from(s);
                    }
                    else
                        itm[key] = id + ".summary";
                    console.log(itm)
                    setItems([...items]);
                } 
                dispatchToast(<Toast appearance='inverted'><ToastTitle>Updated Manuscript</ToastTitle><ToastBody>{itm.title}</ToastBody></Toast>, {intent: "success"})
            })
            return ;
        }

        //Perform Action
        if(key === "analyze")
            navigate("/analyze", item);
        else if(key === "abstract")
            getSuppDoc(user, saveUser, {item: item.id + ".abstract"},  (txt) => { setDialogData({text: txt, title: item.title})});
        else if(key === "cover")
            getSuppDoc(user, saveUser, {item: v.item}, (txt) => { setDialogData({text: txt, title: item.title})});
        else if(key === "cv" || key === "demos") {
            SubmitData("/api/uus", user, saveUser, v, ret => {
                var itm = items.find(vv => vv.id === item.id && vv.lid === item.lid);
                itm.authors = ret.authors;
                setItems([...items])
            })
        }
        else if(key === "messages") {
            if(item.messages)
                navigate("/messages", {state:{thread: user.current.scholarsift.profile + "-" + item.id, part: -1}})
            else
                navigate("/messages", {state:{header:{from:user.current.scholarsift.profile, to: item.to}, title: item.title, thread: user.current.scholarsift.profile + "-" + item.id}})
        }
        else if(key === "submit_more") {
            var data = new FormData();
            var keys = Object.keys(item);
            keys.forEach(itemKey => {
                if(!(itemKey === "id" || itemKey === "mtype" || itemKey === "title")) {
                    var kk = Object.keys(item[itemKey]);
                    kk.filter(kv => item[itemKey][kv] instanceof File).forEach(kv => {
                        data.append(kv, item[itemKey][kv]);
                        item[itemKey][kv] = item[itemKey][kv].name;
                    })
                    item[itemKey].lid = journalNames.find(jn => jn.name === itemKey).id;
                }
            })
            data.append("md", JSON.stringify(item));
            SubmitData("/api/submitmore", user, saveUser, data, v => { setItems(Array.isArray(v) ? [...items, ...v] : [...items, v])})
        }
        else
            console.log(v)
    }

    console.log(items)

    return  items === undefined || nmc === undefined ?
            <LoadingAnimation msg="Loading" /> :
            <ManageUserSubmissions 
                {...props}
                journals={journals}
                GetJournals={GetJournals}
                manuscripts={items}
                handleItemClick={handleItemClick}
                textData={dialogData}
            />
}

export const UserSubmissions = props => {
    const { state } = useLocationState();
    const h = useRef();

    useEffect(() => {
        let v = state.viewport.h - (state.viewport.m + state.viewport.f + state.viewport.o);
        if (v !== h.current) h.current = v;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.viewport]);

    return  <ViewPort>
                <div style={{ height: h.current}}>
                    <UserSubmissionPortalComponent 
                        {...props} 
                        width={props.width ? props.width : state.viewport.w}
                        height={h.current - 32}
                    />
                </div> 
            </ViewPort>

}

export default UserSubmissions;