import { Debug } from "ss-lib/dist/cjs";
import { APIErrorHandler } from "../../../actions/APIErrorHandler";
import axios from 'axios';

export const fetchItems = async (user, saveUser, setItems) => {
    axios
      .post("/api/submissions", {id: user.current.scholarsift.profile},
        {
          headers: {
            Authorization: "Bearer " + user.current.token.id,
            Coda: user.current.scholarsift.coda,
          },
        }
      )
      .then((response) => {
        if (response.data.submissions) 
          setItems(response.data.submissions);
      })
      .catch((err) => {
        APIErrorHandler(err, user, saveUser, (v) => { fetchItems(user, saveUser, setItems); });
      });
}

export const UpdateFile = (user, saveUser, file, md, callBack) => {
  var data = new FormData();
  data.append("file", file);
  data.append("md", JSON.stringify(md));
  axios.post("/api/resubmit", data,  {
          headers: {
            Authorization: "Bearer " + user.current.token.id,
            Coda: user.current.scholarsift.coda,
          },
        })
        .then((response) => {
          if(Debug())
            console.log(response)
          callBack(response.data)
        })
        .catch((err) => {
          APIErrorHandler(err, user, saveUser, (v) => { UpdateFile(user, saveUser, file, md, callBack); });
        })
}

export const ListJournals = async (user, saveUser, type, callBack) => {
  axios.post("/api/lslr", {type:type},
    {
        headers: {
        Authorization: "Bearer " + user.current.token.id,
        Coda: user.current.scholarsift.coda,
        },
    })
    .then(res => {
        //Process
        callBack(res.data);
    })
    .catch(err => {
        APIErrorHandler(err, user, saveUser, () => { ListJournals(user, saveUser, type, callBack) });
    })
}

export const SubmitData = (endpoint, user, saveUser, data, callBack) => {
  if(Debug()) {
    console.log(endpoint)
    console.log(data)
  }
  axios.post(endpoint, data,  {
    headers: {
      Authorization: "Bearer " + user.current.token.id,
      Coda: user.current.scholarsift.coda,
    },
  })
  .then((response) => {
    if(Debug())
      console.log(response)
    if(callBack)
      callBack(response.data)
  })
  .catch((err) => {
    APIErrorHandler(err, user, saveUser, (v) => { SubmitData(endpoint, user, saveUser, data, callBack); });
  })
}
