import { Debug } from "ss-lib";
import { APIErrorHandler } from "../../../actions/APIErrorHandler";
import axios from "axios";

export const SendMessage = (user, saveUser, msg, callBack) => {
    axios
        .post("/api/sendmsg", msg,
          {
            headers: {
              Authorization: "Bearer " + user.current.token.id,
              Coda: user.current.scholarsift.coda,
            },
          }
        )
        .then((response) => {
          callBack(response.data)
        })
        .catch((err) => {
          APIErrorHandler(err, user, saveUser, (v) => { SendMessage(user, saveUser, msg, callBack); });
        });
}

export const ListMessages = (user, saveUser, setMessages) => {
    axios
        .post("/api/lsmsg", {profile: user.current.scholarsift.profile},
          {
            headers: {
              Authorization: "Bearer " + user.current.token.id,
              Coda: user.current.scholarsift.coda,
            },
          }
        )
        .then((response) => {
          setMessages(response.data);
        })
        .catch((err) => {
          APIErrorHandler(err, user, saveUser, () => { ListMessages(user, saveUser, setMessages) });
        });
}

export const TouchThread = (user, saveUser, data) => {
  axios.post("/api/touchmsg", data, {
      headers: {
        Authorization: "Bearer " + user.current.token.id,
        Coda: user.current.scholarsift.coda,
      },
    }
  )
  .then((response) => {
    if(Debug())
      console.log("Touch Thread status: " + response.status)
  })
  .catch((err) => {
    APIErrorHandler(err, user, saveUser, () => { TouchThread(user, saveUser, data) });
  });
}