import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ViewPort from "../../Layout/ViewPort";
import useUser from '../../../hooks/useUser';
import useLocationState from '../../../hooks/useLocationState';
import { Debug, LoadingAnimation, ManageSubmissions, defaultGroupPrefs, defaultPagePrefs, ssToast  } from "ss-lib";
import { fetchEditors, fetchItems, fetchNMC, AssignItems, CreateSubmissionSet, UpdateGroupPrefs, UpdatePagePrefs, getSuppDoc, UpdateSubmissionStatus, UpdateSubmissionTags, UpdateSubmissionNotes, GetTemplate } from './actions';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Toast, ToastBody, ToastTitle, useToastController } from '@fluentui/react-components';

const expectedPath = "/submissions/manager";

const getPagePrefs = (user, location, props) => {
    var pagePrefs = defaultPagePrefs[location.pathname] ? {...defaultPagePrefs[location.pathname]} : {};
    if(user.current && user.current.scholarsift &&
        user.current.scholarsift.pinfo && user.current.scholarsift.pinfo["page prefs"]) {
            if(user.current.scholarsift.pinfo["page prefs"][location.pathname])
                pagePrefs = user.current.scholarsift.pinfo["page prefs"][location.pathname]
            else if(user.current.scholarsift.pinfo["page prefs"][expectedPath])
                pagePrefs = user.current.scholarsift.pinfo["page prefs"][expectedPath]
    }
    return props.showControls !== undefined ? {...pagePrefs, showControls: props.showControls} : {...pagePrefs}
}

const getAllGroupPrefs = (user, location) => {
    if (user.current && user.current.scholarsift && user.current.scholarsift.groupPrefs) {
        var arr = [];
        user.current.scholarsift.groupPrefs.map(v => {
            if(v[location.pathname])
                arr.push({...v[location.pathname], lid: v.lid, id: v.id});
            else if(v[expectedPath])
                arr.push({...v[expectedPath], lid: v.lid, id: v.id})
        });
        return arr;
    }
    return defaultGroupPrefs[expectedPath];
}

const getGroupPrefs = (allGroupPrefs, pagePrefs) => {
    if(!pagePrefs || !pagePrefs.activeView || !allGroupPrefs )
        return allGroupPrefs.length > 0 ? allGroupPrefs[allGroupPrefs.length - 1] : {...defaultGroupPrefs[expectedPath][0]};
    var f = allGroupPrefs.find(v => v.name === pagePrefs.activeView);
    return f ? f : allGroupPrefs.length > 0 ? allGroupPrefs[allGroupPrefs.length - 1] : {...defaultGroupPrefs[expectedPath][0]}
}

export const SubmissionPortalComponent = (props) => {
    const { dispatchToast } = useToastController(ssToast);
    const location = useLocation();
    const navigate = useNavigate();
    const { user, saveUser, setTitle } = useUser();
    const [pagePrefs, setPagePrefs] = useState(getPagePrefs(user, location, props));
    const [activeView, setActiveView] = useState(pagePrefs.activeView);
    const [allGroupPrefs, setAllGroupPrefs] = useState(getAllGroupPrefs(user, location));
    const [groupPrefs, setGroupPrefs] = useState(getGroupPrefs(allGroupPrefs, pagePrefs))
    const [dialogData, setDialogData] = useState();
    
    const [itemCS, setItemCS] = useState(false);
    const [items, setItems] = useState();
    const [fetchingItems, setFetchingItems] = useState(false);
    const [editors, setEditors] = useState();
    const [fetchingEditors, setFetchingEditors] = useState(false);
    const [tags, setTags] = useState([]);
    const [nmc, setNMC] = useState();
    const [fetchingNMC, setFetchingNMC] = useState(false);
    const [template, setTemplate] = useState();
    const [customTemplate, setCustomTemplate] = useState()

    useEffect(() => {
        setTitle("Submission Manager");
        document.title = "Submission Manager";
    })

    useEffect(()=> {
        if(groupPrefs.id === -1)
            return ;

        if(items === undefined && !fetchingItems) {
            setFetchingItems(true);
            fetchItems(user, saveUser, groupPrefs.lid, (v) => {
                var set = new Set();
                v.forEach(item => { if(item.tags) {
                        var s = new Set(item.tags);
                        item.tags.forEach(vv => { s.add(vv); set.add(vv) })
                        if(s.size > 0)
                            item.tags = [...s];
                        else
                            delete item.tags;
                    }
                })
                setItems(v);
                setTags([...set]);
                setFetchingItems(false)
            }, () => { setFetchingItems(false)})
        }
        if(editors === undefined && !fetchingEditors) {
            if(groupPrefs.canAssign) {
                setFetchingEditors(true);
                fetchEditors(user, saveUser, groupPrefs.lid, setEditors, () => { setFetchingEditors(false)})
            }
            else
                setEditors([]);
        }
        if(nmc === undefined && !fetchingNMC) {
            setFetchingNMC(true);
            fetchNMC(user, saveUser, (v) => {setNMC(v); setFetchingNMC(false)})
        }
    }, [groupPrefs])


    useEffect(() => {
        if(!itemCS && items && nmc) {
            var itms = [...items];
            itms.forEach(itm => {
                var nm = nmc.find(nm => nm.id === +itm.id);
                itm.messages = nm ? nm.ct : 0;
            })
            setItems(itms);
            setItemCS(true);
        }
    }, [items, nmc])

    useEffect(() => {
        if(activeView !== pagePrefs.activeView) {
            setActiveView(pagePrefs.activeView);
            setGroupPrefs(getGroupPrefs(allGroupPrefs, pagePrefs));
        }
    }, [pagePrefs.activeView])

    const createSubmissionSet = set => {
        //Dispatch Toast
        dispatchToast(<Toast appearance='inverted'><ToastTitle>Creating Submission Set</ToastTitle><ToastBody>Creating {set.name}</ToastBody></Toast>, {intent: "information"});

        //Update API
        CreateSubmissionSet(user, saveUser, {...set, offset: new Date().getTimezoneOffset(), group: groupPrefs.id}, (id) => {

            //Update States
            set.subset = id;
            setGroupPrefs({...groupPrefs, availableSets: groupPrefs.availableSets ? [...groupPrefs.availableSets, set] : [set]})
            var gp = [...allGroupPrefs];
            gp.forEach(v => {
                if(v.name === pagePrefs.activeView)
                    v.availableSets.push(set);
            })
            setAllGroupPrefs(gp);
            var u = {...user.current};
            u.scholarsift.groupPrefs.forEach(v => {
                if(v[location.pathname].name === pagePrefs.activeView)
                    v[location.pathname].availableSets.push(set);
            })
            saveUser(u);

            //Dispatch Toast
            dispatchToast(<Toast appearance='inverted'><ToastTitle>Created Submission Set</ToastTitle><ToastBody>{set.name} is ready.</ToastBody></Toast>, {intent: "information"});
        });
    }

    const toggleControls = () => {
        var data =  {...pagePrefs, showControls: !pagePrefs.showControls};
        setPagePrefs(data);
        UpdatePagePrefs(user, saveUser, {key: location.pathname, value: data});
    }

    const updatePagePrefs = v => {
        const data = {...pagePrefs, [v.k] : v.v}
        setPagePrefs(data);
        UpdatePagePrefs(user, saveUser, {key: location.pathname, value: data});
    }

    const updateGroupPrefs = u => {
        //Update States
        var g = {...groupPrefs, [u.k]: u.v};
        setGroupPrefs(g)
        
        var gp = [...allGroupPrefs];
        gp.forEach(v => {
            if(v.name === pagePrefs.activeView)
                v[u.k] = u.v;
        })
        setAllGroupPrefs(gp);
        
        var usr = {...user.current};
        usr.scholarsift.groupPrefs.forEach(v => {
            if(v[location.pathname].name === pagePrefs.activeView)
                v[location.pathname][u.k] = u.v;
        })
        saveUser(usr);

        UpdateGroupPrefs(user, saveUser, {key: location.pathname, value: g})
    }

    const handleItemClick = v => {
        const { item, key } = v;
        if(key === "analyze")
            navigate("/analyze", item);
        else if(key === "messages") {
            if(item.messages)
                navigate("/messages", {state:{thread: user.current.scholarsift.profile + "-" + item.id, part: -1}})
            else
                navigate("/messages", {state:{header:{from:user.current.scholarsift.profile, to: item.to}, title: item.title, thread: user.current.scholarsift.profile + "-" + item.id}})
        }
        else if(key === "abstract")
            getSuppDoc(user, saveUser, {id: item.id + ".abstract"}, (txt) => { setDialogData({text: txt, title: item.title})});
        else if(key === "cover")
            getSuppDoc(user, saveUser, {id: item.cover.indexOf(groupPrefs.lid) === -1 ? item.id + ".cover" : (item.id + "." + item.lid + ".cover")}, (txt) => { setDialogData({text: txt, title: item.title})});
        else {
            console.log(v)
        }
    }

    const handleDialog = v => {
        var data = {};
        if(v.key === "status") {
            data = {
                items: v.data.selected.map(vv => +items.find(item => item.title === vv).id),
                status: v.data.status === "Offer" ? 2 : v.data.status === "Decline" ? 3 : 1,
                task_status: v.data.status === "Offer" || v.data.status === "Decline" ? v.data.status : "In Progress",
                task: v.data.status === "Recommend" || v.data.status === "Do not Recommend" ? v.data.status : undefined,
                lid: groupPrefs.lid, 
                eid: user.current.scholarsift.profile,
                template: customTemplate ? customTemplate : v.data.template,
                offset: v.data.deadlineTime ? new Date().getTimezoneOffset() : undefined,
                deadline: v.data.deadlineDate,
                deadlineHM: v.data.deadlineTime,
            }
            UpdateSubmissionStatus(user, saveUser, data, () => {
                var itms = [...items];
                data.items.forEach(v => {
                    var item = itms.find(itm => +itm.id === v)
                    item.status = data.status;
                    if(item && item.tasks && item.tasks.length > 0)
                    {
                        var task = item.tasks.find(t => t.email === user.current.scholarsift.email)
                        if(task) {
                            task.status = data.task_status;
                            if(data.task)
                                task.name = data.task;
                        }
                    }
                })
                setItems(itms);
            })
        } else if(v.key === "tag") {
            data = {
                id: +v.id,
                lid: groupPrefs.lid,
                selections: v.selections,
                tags: v.tags.filter(t => !tags.find(tag => tag.tag === t))
            }

            dispatchToast(<Toast appearance='inverted'><ToastTitle>Updating Tags</ToastTitle></Toast>, {intent: "information"});

            UpdateSubmissionTags(user, saveUser, data, (newTags) => {
                var itms = [...items];
                var item = itms.find(itm => +itm.id === data.id)
                if(item) {
                    item.tags = data.selections.map(v => ({id: 1, tag:v}));
                    if(newTags && newTags.length)
                        setTags(tags.concat(newTags))
                    setItems(itms);
                }
            })
        } else if(v.key === "notes") {
            data = {
                id: +v.id,
                notes: v.notes,
                lid: groupPrefs.lid
            }

            UpdateSubmissionNotes(user, saveUser, data, () => {
                var itms = [...items];
                var item = itms.find(itm => +itm.id === data.id)
                if(item) {
                    item.notes = v.notes;
                    setItems(itms);
                }
            })
        }
        else {
            if(Debug()) {
                console.log("HANDLE DIALOG")
                console.log(v)
            }
        }
    }

    const Assign = data => {
        var e = data.e === "All" ? editors.map(v => v.id) : data.e.map(v => editors.find(ev => ev.name === v).id);
        var m = data.m === "All" ? items.filter(v => !v.tasks || v.tasks.length === 0 ).map(itm => {
            if(data.a && data.a.indexOf("Group") > -1) {
                var topic = "";
                try {
                    var stats = JSON.parse(itm.stats);
                    const { topics } = stats;
                    topic = Object.keys(topics).sort((a, b) => topics[b] - topics[a]).map(v => v)[0];
                }
                catch {}
                return {id: +itm.id, topic: topic}
            }
            return +itm.id;
        }) : data.m.map(v => {
            var itm = items.find(item => item.title === v);
            if(data.a && data.a.indexOf("Group") > -1) {
                var topic = "";
                try {
                    var stats = JSON.parse(itm.stats);
                    const { topics } = stats;
                    topic = Object.keys(topics).sort((a, b) => topics[b] - topics[a]).map(v => v)[0];
                }
                catch {}
                return {id: +itm.id, topic: topic}
            }
            return +itm.id;
        });
        dispatchToast(<Toast appearance='inverted'><ToastTitle>Updating Manuscripts</ToastTitle><ToastBody>Updating {m.length} Manuscripts</ToastBody></Toast>, {intent: "information"});
        AssignItems(user, saveUser, {...data, e:e, m: m, lid: groupPrefs.lid}, (assignments) => {
            var itms = [...items];
            assignments.forEach(v => {
                var itm = itms.find(item => +item.id === v.id);
                if(itm) {
                    var task = {
                        email: editors.find(editor => editor.id === v.eid).email,
                        status: v.status,
                        name: v.name
                    };
                    itm.tasks = itm.tasks ? [...itm.tasks, task] : [task];
                }
            })
            setItems(itms); 
            dispatchToast(<Toast appearance='inverted'><ToastTitle>Updated Manuscripts</ToastTitle><ToastBody>Update complete.</ToastBody></Toast>, {intent: "information"});});
    }

    if(Debug()) {
        console.log("ITEMS")
        console.log(items)
        console.log(customTemplate)
    }

    return  items === undefined || editors === undefined || tags === undefined || nmc === undefined ?
            <LoadingAnimation msg="Loading" /> :
            <ManageSubmissions
                prefs={groupPrefs}
                allGroupPrefs={allGroupPrefs}
                pagePrefs={pagePrefs}
                width={props.width}
                height={props.height}
                manuscripts={items}
                email={user.current && user.current.scholarsift ? user.current.scholarsift.email : undefined}
                editors={editors}
                tags={tags}
                textData={dialogData}
                AssignSelected={Assign}
                BulkAssign={Assign}
                createSubmissionSet={createSubmissionSet}
                handleItemClick={handleItemClick}
                handleDialog={handleDialog}
                toggleControls={toggleControls}
                updateGroupPrefs={updateGroupPrefs}
                updatePagePrefs={updatePagePrefs}
                getTemplate={id => {GetTemplate(user, saveUser, id, (v) => {setTemplate(v); setCustomTemplate(undefined)})}}
                template={template}
                SaveTemplate={setCustomTemplate}
            />
}

export const SubmissionPortal = props => {
    const { state } = useLocationState();
    const h = useRef();

    useEffect(() => {
        let v = state.viewport.h - (state.viewport.m + state.viewport.f + state.viewport.o);
        if (v !== h.current) h.current = v;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.viewport]);

    return  <ViewPort>
                <div style={{ height: h.current}}>
                    <SubmissionPortalComponent 
                        {...props} 
                        width={props.width ? props.width : state.viewport.w}
                        height={h.current - 32}
                    />
                </div> 
            </ViewPort>

}

export default SubmissionPortal;