import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Toast, ToastTitle, ToastBody, useToastController } from '@fluentui/react-components'
import { Debug, Profile, defaultProfile, ssToast  } from 'ss-lib';
import useUser from '../../hooks/useUser';
import ViewPort from "../Layout/ViewPort";
import useLocationState from "../../hooks/useLocationState";
import { DeleteProfile as DelProfile, UpdateProfile } from './actions';

import creds from '../../creds.json';

const getProfile = (props, state, user) => {
  if(props.pdata)
    return props.pdata;
  if(state && state.pdata)
    return state.pdata;
  if(user.current && user.current.scholarsift)
    return user.current.scholarsift.pinfo;
  return defaultProfile;
}

export const ProfileParent = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { dispatchToast } = useToastController(ssToast);
  const { user, saveUser, warningDisplayed, setWarningDisplayed } = useUser();
  const [profile, setProfile] = useState(getProfile(props, location.state, user));

  useEffect(() => {
    if(Debug())
      console.log("Element Effect")
    if(location.pathname.indexOf("welcome") === -1 && location.state && location.state.id) {
      if(Debug())
        console.log("Setting Element")
      var elmt = document.getElementById(location.state.id);
      if(elmt)
        elmt.focus();
    }
  }, [location.state])

  useEffect(() => {
    if(Debug())
      console.log("Profile Effect")
    let p = getProfile(props, location.state, user);
    if(p !== profile) {
      if(Debug())
        console.log("Setting Profile")
      setProfile(p)
    }
      
  }, [props, user, location.state]);

  const SaveProfile = p => {
    UpdateProfile(
        user,
        saveUser,
        p,
        (v) => {
          if(props.profile)
            setProfile(p);
          else
            saveUser({...user.current, scholarsift: {...user.current.scholarsift, pinfo: p}});
          dispatchToast(<Toast appearance='inverted'><ToastTitle>Success</ToastTitle><ToastBody>Saved Profile</ToastBody></Toast>, {intent: "success"}) 
        }
      );
  }

  const DeleteProfile = p => {
    DelProfile(user, saveUser, profile, navigate("/signin"));
  }

  const CV = v => {
  }

  const showIncomplete = () => {
    if(!warningDisplayed) {
      setWarningDisplayed(true);
      dispatchToast(<Toast appearance='inverted'><ToastTitle>Notification</ToastTitle><ToastBody>Your profile is incomplete</ToastBody></Toast>, {intent: "warning"})
    }
  }

  return <Profile profile={profile} orcidClientId={creds.orcid.clientId} Save={SaveProfile} Delete={DeleteProfile} CVFile={CV} showIncomplete={showIncomplete}/>
};

export const ProfileWrapper = props => {
  const { state } = useLocationState();
  const { setTitle } = useUser();

  useEffect(() => {
    setTitle("My Profile");
    document.title = "ScholarSift - Profile"
  })

  return  <ViewPort>
            <div
              style={{
              minHeight:
                state.viewport.h -
                (state.viewport.m + state.viewport.f),
              }}
            >
              <ProfileParent {...props} />
            </div>
          </ViewPort>
}

export default ProfileParent;
